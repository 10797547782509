<template>
  <div class="intelligent-devices">
    <div class="wrap-content hide-scrollbar">
      <div class="navigation">
        <img :src="menuIcon" alt="" />
        <img :src="switchImg" alt="" />
      </div>
      <div class="tips-box">
        <div class="top">
          <span>Hi, John</span>
          <img :src="handSignEmojiIcon" alt="" />
        </div>
        <div class="bottom">Welcome back!</div>
      </div>
      <div class="tip-title">Your Ampere Devices</div>

      <div class="device-box">
        <div
          class="device-item"
          v-for="(item, index) in deviceCardList"
          :key="'device' + index"
        >
          <div class="device-item_left">
            <div class="img">
              <img :src="item.image" alt="" />
            </div>
            <div class="info">
              <div class="name">{{ item.name }}</div>
              <div class="operate">
                <div class="operate_item">
                  <img :src="editIcon" alt="" />
                  <span>Edit</span>
                </div>
                <div class="operate_item">
                  <img :src="detailIcon" alt="" />
                  <span>Delete</span>
                </div>
              </div>
            </div>
          </div>
          <div class="device-item_right">
            <img :src="rightArrow2" alt="" />
          </div>
        </div>
      </div>
      <div class="add-btn">
        <img :src="addIcon" alt="" />
        <span>Add an Ampere Device</span>
      </div>
      <!-- 注册盒子 -->
      <div class="register-box">
        <div class="bgc">
          <img :src="registerImage" alt="" />
          <div class="top">Register your Ampere devices for warranty</div>
          <div class="tip">
            <span
              >Register your device within 30-days for the Ampere one-year
              warranty</span
            >
          </div>
          <div class="btn-box">
            <span>Register</span>
            <img :src="rightArrow1" alt="" />
          </div>
          <!-- 遮罩层 -->
          <div class="mask"></div>
        </div>
      </div>

      <div class="members-lounge">
        <img :src="userAvatar" alt="" />
        <div class="border-out">
          <div class="border-center">
            <div class="border-in"></div>
          </div>
        </div>
        <div class="text">Members Lounge</div>
      </div>
      <div class="product-box">
        <div class="product-item">
          <img :src="productImage" alt="" />
          <div class="product-info">
            <div class="product-title">Promo 1 Title goes here</div>
            <div class="product-content">
              <span
                >Sed ut perspiciatis unde omnis iste natus error sit volupta tem
                accusantium doloremque laudan tium totam rem.</span
              >
            </div>
          </div>
        </div>
        <div class="product-item">
          <img :src="productImage" alt="" />
          <div class="product-info">
            <div class="product-title">Promo 2 Title goes here</div>
            <div class="product-content">
              <span
                >Sed ut perspiciatis unde omnis iste natus error sit volupta tem
                accusantium doloremque laudan tium totam rem.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// 顶部图片
const menuIcon = require("@/assets/images/imitatepage/ampere-slices/menu-icon.png");
const switchImg = require("@/assets/images/imitatepage/ampere-slices/switch-img.png");
// 第二个盒子
const handSignEmojiIcon = require("@/assets/images/imitatepage/ampere-slices/hand_sign_emoji_icon.png");
// 第四个盒子
const editIcon = require("@/assets/images/imitatepage/ampere-slices/edit-icon.png");
const detailIcon = require("@/assets/images/imitatepage/ampere-slices/delete-icon.png");
const rightArrow2 = require("@/assets/images/imitatepage/ampere-slices/right-arrow2.png");

// 第五个盒子
const addIcon = require("@/assets/images/imitatepage/ampere-slices/add-icon.png");
// 第六个盒子
const rightArrow1 = require("@/assets/images/imitatepage/ampere-slices/right-arrow1.png");

// 第七个
const userAvatar = require("@/assets/images/imitatepage/ampere-slices/user-avatar.png");
const registerImage = require("@/assets/images/imitatepage/ampere-slices/register-image.png");
// 最后的盒子
const productImage = require("@/assets/images/imitatepage/ampere-slices/glasses-img.png");

// 设备卡片数据
const deviceCardList = [
  {
    name: "John's Dusk",
    image: require("@/assets/images/imitatepage/ampere-slices/glasses-img2.png"),
  },
  {
    name: "John's Shower Power",
    image: require("@/assets/images/imitatepage/ampere-slices/glasses-img3.png"),
  },
];
</script>

<style lang="scss" scoped>
.intelligent-devices {
  position: relative;
  width: 100%;
  height: 100%;

  .wrap-content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    padding-bottom: 60px;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 16px;

    .navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 35px;
      img:nth-child(1) {
        width: 32px;
        height: 23px;
      }
      img:last-child {
        width: 50px;
        height: 50px;
      }
    }

    .tips-box {
      color: #192753;
      margin-top: 15px;
      .top {
        display: flex;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        img {
          width: 29px;
          height: 28px;
          margin-left: 10px;
        }
      }
      .bottom {
        font-size: 14px;
        margin-top: 6px;
      }
    }

    .tip-title {
      font-size: 20px;
      color: #192753;
      margin: 45px 0 19px;
    }

    .device-box {
      .device-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 108px;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);
        border-radius: 15px;
        margin-bottom: 20px;
        &_left {
          display: flex;
          align-items: center;
          .img {
            width: 108px;
            height: 108px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .info {
            .name {
              color: #192753;
              font-weight: 500;
            }
            .operate {
              display: flex;
              align-items: center;
              margin-top: 5px;
              &_item {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #b5b5b5;
                margin-right: 17px;
                img {
                  width: 11px;
                  height: 12px;
                  margin-right: 3px;
                }
              }
            }
          }
        }
        &_right {
          margin-right: 10px;
          img {
            width: 8px;
            height: 13px;
          }
        }
      }

      .device-item:last-child {
        .device-item_left {
          .img {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 55px;
              height: 67px;
            }
          }
        }
      }
    }

    .add-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #1dc497;
      height: 55px;
      border-radius: 27px;
      color: #fff;
      font-size: 14px;

      img {
        width: 19px;
        height: 19px;
        margin-right: 20px;
      }
    }

    // 注册盒子
    .register-box {
      width: 100%;
      height: 240px;
      margin: 30px 0 20px;
      border-radius: 15px;
      overflow: hidden;

      .bgc {
        position: relative;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }

        .top {
          position: absolute;
          top: 30px;
          left: 20px;
          font-size: 20px;
          color: white;
          z-index: 2;
          max-width: 230px;
        }
        .tip {
          position: absolute;
          top: 90px;
          left: 20px;
          color: #b9bdcc;
          font-size: 11px;
          z-index: 2;
          max-width: 230px;
        }

        .btn-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #1dc497;
          width: 156px;
          height: 43px;
          border-radius: 21px;
          color: #fff;
          font-size: 14px;
          padding: 0 20px;
          box-sizing: border-box;
          position: absolute;
          bottom: 31px;
          left: 20px;
          z-index: 2;

          img {
            width: 8px;
            height: 13px;
            margin-top: 2px;
          }
        }

        .mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // background: linear-gradient(180deg, transparent 0%, #050811 100%);
          box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);
        }
      }
    }

    // 会员休息室
    .members-lounge {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      img {
        width: 56px;
        height: 56px;
      }
      .border-out {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 282px;
        height: 50px;
        // border: 0.43px solid #1dc497;
        box-shadow: 0 0 0 0.43px #1dc497;
        border-radius: 25px;
        opacity: 0.43;

        .border-center {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 276px;
          height: 44px;
          // border: 1px solid #1dc497;
          box-shadow: 0 0 0 0.86px #1dc497;
          border-radius: 22px;
          .border-in {
            width: 270px;
            height: 38px;
            box-shadow: 0 0 0 2px #1dc497;
            // border: 2px solid #1dc497;
            border-radius: 19px;
            text-align: center;
            line-height: 38px;
          }
        }
      }

      .text {
        position: absolute;
        top: 13px;
        left: 114px;
        color: rgba(25, 39, 83, 1);
        font-weight: 700;
        font-size: 20px;
      }
    }

    // 产品
    .product-box {
      margin: 20px 0;
      .product-item {
        display: flex;
        flex-direction: column;
        height: 307px;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 25px;
        img {
          width: 100%;
          height: 189px;
        }
        .product-info {
          padding-left: 20px;

          .product-title {
            color: #192753;
            font-weight: 600;
            margin: 25px 0 15px;
          }
          .product-content {
            font-size: 11px;
          }
        }
      }
    }
  }
}
</style>
